<template>
  <section class="gardenManagementPage">
    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="id" label="ID" min-width="40" />
        <el-table-column prop="operator" label="操作者" min-width="40" />
        <el-table-column prop="content" label="说明" min-width="100" />
        <el-table-column prop="username" label="微信昵称" min-width="60" />
        <el-table-column prop="addDay" label="调整时间" min-width="60" />
      </el-table>
      <GPagination
        v-show="total > 0"
        ref="ref_GPagination"
        :total="total"
        :page.sync="searchForm.page"
        :size.sync="searchForm.size"
        @pagination="querycshopmallIntegralLogPage"
        :page-sizes="pageSizes"
      />
    </TableContainer>

    
  </section>
</template>

<script>
import { parseTime } from "@utils/filters";
import {
  querycshopmallIntegralLogPage,
  updatejfProduct,
  deljfProduct,
  addjfProduct,
  queryjfProductclassPage,
  productintegralSum,
} from "@api/bx/api_contentManagement";
import uploadPics from "@/components/Upload/uploadPics.vue";

export default {
  components: {
    uploadPics,
  },
  data() {
    return {
     
      searchForm: {
        page: 1,
        size: 15,
      },
      total: 0,
      tableData: [],
      pageSizes: [15, 30, 45, 60, 75],
      editor: false,
      index: 0,
      parmas: {
        overday: "",
        productcode: "",
        productid: "",
        productimg: "",
        productintegral: "",
        productname: "",
        productprice: "",
        productunit: "",
        upanddown: "0",
        num: "",
      },
      radio: "1",
    };
  },
  created() {
    // this.currentTime = parseTime(new Date(), "{y}-{m}-{d}");
    this.querycshopmallIntegralLogPage();
  },
  methods: {
    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },
    clearData() {
      this.parmas.overday = "";
      this.parmas.productcode = "";
      this.parmas.productid = "";
      this.parmas.productimg = "";
      this.parmas.productintegral = "";
      this.parmas.productname = "";
      this.parmas.productprice = "";
      this.parmas.productunit = "";
      this.parmas.upanddown = "0";
      this.parmas.num = "";
      this.imageFileList = [];
    },

    // 分类列表
    queryjfProductclassPage() {
      queryjfProductclassPage({
        page: 1,
        size: 15,
        classname: "",
      }).then((res) => {
        this.typeList = res.records;
        this.parmas.productid = res.records[0].id;
        this.index = 0;
      });
    },

    // 列表
    querycshopmallIntegralLogPage() {
      querycshopmallIntegralLogPage(this.searchForm).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },

    inputPrice() {
      // this.parmas.productintegral =this.parmas.productprice * this.typeList[this.index].coefficient;
      if (this.parmas.productprice !== "") {
        productintegralSum({
          productid: this.parmas.productid,
          productprice: this.parmas.productprice,
        }).then((res) => {
          this.parmas.productintegral = res.productintegral;
        });
      } else {
        this.parmas.productintegral = "";
      }
    },
    //添加
    add() {
      this.clearData();
      this.editor = false;
      this.queryjfProductclassPage();
      this.themeDialog.visible = true;
    },
    //确定
    submitThemeForm() {
      if (this.parmas.productname == "") {
        this.$alert("请输入产品标题", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.productcode == "") {
        this.$alert("请输入产品规格", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.productprice == "") {
        this.$alert("请输入产品价格", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.num == "" || this.parmas.num == null) {
        this.$alert("请输入产品库存", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.overday == "") {
        this.$alert("请选择产品过期时间", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.parmas.productimg == "") {
        this.$alert("请上传产品图片", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.editor) {
        updatejfProduct(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.clearData();
          this.querycshopmallIntegralLogPage();
        });
      } else {
        addjfProduct(this.parmas).then((res) => {
          this.themeDialog.visible = false;
          this.clearData();
          this.querycshopmallIntegralLogPage();
        });
      }
    },
    //取消按钮
    resetThemeForm() {
      // this.clearData()
      this.themeDialog.visible = false;
      this.querycshopmallIntegralLogPage();
    },
    //编辑
    editorClick(row) {
      this.editor = true;
      this.parmas = row;
      // console.log(this.parmas)
      this.imageFileList = [
        {
          name: "",
          url: row.productimg,
        },
      ];
      this.themeDialog.visible = true;
    },
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.red {
  color: red;
}
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
</style>
